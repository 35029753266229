import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent, HomeDebugComponent} from './pages/home/home.component';
import {StatsComponent} from './pages/stats/stats.component';

/**
 * PM (19.06.2019)
 * @see https://angular.io/guide/router
 *      https://medium.com/@christo8989/angular-6-url-parameters-860db789db85
 *      https://medium.com/developing-an-angular-4-web-app/adding-routes-for-login-and-home-view-components-be676d786471
 *      https://www.sitepoint.com/component-routing-angular-router/ # children
 */
const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'pool/:pool', component: HomeComponent}, //PM (18.12.2019) the multi survey management has been enabled
  {path: 'debug', component: HomeDebugComponent}, //PM (18.12.2019) the multi survey management has been enabled
  {path: 'debug/:pool', component: HomeDebugComponent}, //PM (18.12.2019) the multi survey management has been enabled
  //{path: 'stats', component: StatsComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
