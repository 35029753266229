import {Pool, QuestionType} from './Pool';
import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';

/**
 * the question pool for the fair taking place in january 2020
 */
export class Pools202001 extends Pool {
  readonly slug = '2020.01';
  map = {
    de: [
      {
        type: QuestionType.radio,
        slug: 'age_range',
        question: 'Zu welcher Altersgruppe gehörst du?',
        answers: ['18 - 25', '26 - 35', '36 - 45', '46 - 55', '56 - 65', '66 - 75', '76 - 85', '86 - xx']
      },
      {
        type: QuestionType.check,
        slug: 'activities',
        question: 'Bist du sportlich aktiv?',
        answers: ['Radfahren', 'Motorradfahren', 'Moutainbike', 'Laufen', 'Wandern', 'Schwimmen', 'Wandern']
      },
      {
        type: QuestionType.radio,
        slug: 'frequency',
        question: 'Wie oft werden sportliche Aktivitäten unternommen?',
        answers: ['Nie', 'Selten', 'Unregelmäßig', 'Regelmäßig', 'Täglich']
      },
      {
        type: QuestionType.radio,
        slug: 'ownership',
        question: `Besitzt du Produkte von ${DataService.company}?`,
        answers: ['Nein', 'Ja']
      },
      {
        type: QuestionType.check,
        slug: 'interest_product',
        question: 'An welchen Produkten bist Du interessiert?',
        answers: ['Beleuchtung', 'Bikecomputer <strong>mit</strong> GPS', 'Bikecomputer <em>ohne</em> GPS', 'Wearables', 'Zubehör']
      },
      {
        type: QuestionType.check,
        slug: 'interest_reason',
        question: `Warum interessierst Du Dich für die Produkte von ${DataService.company}.`,
        answers: ['Marken Image', 'Funktionen', 'Preis/Leistung', 'Nur aus Interesse ohne Kaufabsicht']
      }
    ]
  };
}
