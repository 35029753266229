import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';

export class ThesaurusDE extends Thesaurus {
  slug = 'de';
  label = 'Deutsch';
  //...
  success = 'Viel Erfolg beim Gewinnspiel.';
  //...
  agreed = 'Einverstanden';
  close = 'Schließen';
  cookies = 'Zur Verbesserung der Funktionalität unserer Seite benutzen wir Cookies. Mit der Nutzung der Seite erklären Sie sich mit der Verwendung der Cookies einverstanden.';
  email = 'E-mail Adresse';
  email_title = `Wir brauchen deine ${this.email} um dich bei einem möglichen Gewinn erreichen zu können.`;
  error = 'Serverfehler';
  notification_email = `Das Feld für die ${this.email} entweder leer oder die ${this.email} ist nicht gültig.`;
  notification_error = 'Die Daten konnten nicht gespeichert werden. Bitte versuch später nochmal.';
  notifications = {
    exists: `Deine ${this.email} wurde bereits registriert.<br>${this.success}`,
    saved: 'Vielen Dank. Deine Daten wurden gespeichert.<br>'+ this.success
  };
  form_to = 'Zu dem Formular';
  meta = [
    {
      label: "Impressum",
      href: `${DataService.company_url}/${this.slug}/impressum`
    },
    {
      label: "Datenschutz",
      href: `${DataService.company_url}/${this.slug}/datenschutz`
    }
  ];
  multiple_choice = 'Mehrauswahl möglich.';
  newsletter = `Ja ich möchte Newsletter von ${DataService.company} regelmäßig erhalten.`;
  newsletters = {
    ok: `Du wirst in kürzer Zeit per E-Mail aufgefordet sein, deine ${this.email} zu bestätigen.`,
    duplicate: `Du befindest dich bereits auf unserer Newsletter-Liste.`,
    error: `Die Registrierung für die Newsletter ist unerwartet fehlgeschlagen.`
  };
  no_answer = '<em>keine Angabe</em>';
  reset = 'Formular zurücksetzen';
  screensaver_to = 'Zu dem Bildschirmschoner';
  submit = 'Formular abschicken';
  survey = DataService.company +' - Befragung';
  title = 'Title';

  errors = {
    action_missing: 'Die Aktion fehlt.',
    no_pool_found: 'Keine Befragungsliste vorhanden.',
    auth_failed: 'Da ist ein Fehler aufgettreten.', //Die Authenzifizierung fehlt geschlagen.
    email_not_saved: `Die ${this.email} konnte nicht gespeichert werden. Verusch bitte spätter nochmal.`,
    answers_not_saved: 'Die Antworte konnten nicht gespeichert werden. Verusch bitte spätter nochmal.'
  };
}
