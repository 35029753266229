import {OnInit} from '@angular/core';
import * as $ from 'jquery';
import {Thesaurus} from '../thesauri/thesaurus';
import {Slugify} from './Slugify';
import {DataService} from './data.service';

/**
 * PM (04.07.2019)
 */
export class Element implements OnInit, Slugify {
  readonly images = '/assets/images/';
  readonly docs = '/assets/documents/'; //ML (16.07.2019)
  readonly videos = '/assets/videos/'; //PM (13.12.2019)
  readonly active = 'active'; //flag mostly used as a CSS attribute (such as a class) to set a state
  readonly root = './';
  readonly prefix = 'sigma-'; //PM (17.07.2019)
  protected readonly dom = $(document); //PM (17.07.2019)

  slug: string;
  images2: string;


  ngOnInit(){ this.images2 = this.images + this.slug +'/'; }


  /**
   * this function is used to get DOM (EventTarget) object
   * @param e the DOM event
   */
  elt(e: Event): EventTarget { return e.target || e.currentTarget }

  /**
   * this function is used to get jQuery DOM wrapper object
   * @param e the DOM event
   */
  eltJ(e: Event){ return $(this.elt(e)) }


  protected get thesaurus(){ return Thesaurus } //used in HTML
  protected get dataService(){ return DataService } //used in HTML
}

