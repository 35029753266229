import {LanguageService} from '../utils/language.service';
import {Slugify} from '../utils/Slugify';

/** @see https://www.tutorialsteacher.com/typescript/typescript-enum */
export enum QuestionType {
  radio = 'radio',
  check = 'checkbox'
}

/**
 * @since PM (10.12.2019) the questions pool
 * this class is used to enable a strong separation of the questions pools
 */
export abstract class Pool implements Slugify {
  readonly slug: string;
  readonly map: {
    de: {type: QuestionType, slug: string, question: string, answers: string[]}[],
    /** todo simply repeat the above definition for any new language */
  };

  constructor(protected language: LanguageService){}
}

export class Pool0 extends Pool {
  readonly slug = '0';
  readonly map = {de: []}
}
