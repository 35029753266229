import {DataService} from '../utils/data.service';
import {Slugify} from '../utils/Slugify';

export class Thesaurus implements Slugify {
  static readonly _ = '???';
  static readonly project = DataService.company +' // Survey';
  //...
  slug: string;
  label: string;
  //...

  agreed: any;
  close: string;
  cookies: string;
  email: string;
  email_title: string;
  error: string;
  errors: {action_missing:string, no_pool_found:string, auth_failed:string, email_not_saved:string, answers_not_saved:string};
  form_to: string;
  meta: {label, href}[];
  multiple_choice: string;
  newsletter: string;
  newsletters: {ok:string, duplicate:string, error:string};
  notification_email: string;
  notification_error: string;
  notifications: {exists:string, saved:string};
  no_answer: string;
  reset: string;
  screensaver_to: string;
  readonly socials = [
    {
      slug: "blog",
      href: "https://sigma-inmotion.com/"+ this.slug
    },
    {
      slug: "facebook",
      href: "https://www.facebook.com/SIGMASPORTGermany"
    },
    {
      slug: "instagram",
      href: "https://www.instagram.com/sigma_sport_germany/"
    },
    {
      slug: "youtube",
      href: "https://www.youtube.com/user/SIGMAelektro"
    },
  ];
  submit: string;
  success: string;
  survey: string;
  title: string;
}
