import {Page} from '../../utils/Page';
import {Component} from '@angular/core';
import {LanguageService} from '../../utils/language.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.less'],
  //encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class StatsComponent extends Page{
  slug = 'stats';

  constructor(protected language: LanguageService, protected http: HttpClient){ super(language, http) }
}
