import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/** PM (19.06.2019) @see https://angular.io/guide/http */

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {CookieService} from 'ngx-cookie-service';
import {NavComponent} from './sections/nav/nav.component';
import {FooterComponent} from './sections/footer/footer.component';
import {ModalComponent} from './sections/modal/modal.component';
import {HomeComponent, HomeDebugComponent} from './pages/home/home.component';
import {StatsComponent} from './pages/stats/stats.component';
import {Question} from './sections/question/question.component';



@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    ModalComponent,
    HomeComponent,
    HomeDebugComponent,
    StatsComponent,
    Question
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    HttpClientModule,
    FormsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
