import {Component, Input} from '@angular/core';
import {QuestionType} from '../../thesauri/Pool';
import {Section} from '../../utils/Section';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';

/**
 * @see https://angular.io/api/common/NgForOf
 */
@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.less']
})
export class Question extends Section {
  slug = 'question'
  @Input() question: {type: QuestionType, slug: string, question: string, answers: string[]};
  @Input() form: {};
  @Input() i: Number;
  @Input() debug: boolean;


  /**
   * this function is used to (re-)set the form values of the given question
   * @static
   * @param question
   * @param form
   */
  static set(question: {type: QuestionType, slug: string, question: string, answers: string[]}, form:{}){
    /*
    if(question.type == QuestionType.radio) question.answers.forEach((_, i) => form[question.slug][i] = i == 0);
    else if(question.type == QuestionType.check) question.answers.forEach((_, i) => form[question.slug][i] = false)
    */

    //the binary is used here to avoid having to convert the values on the server side
    if(question.type == QuestionType.radio) question.answers.forEach((_, i) => form[question.slug][i] = i == 0 ? 1 : 0);
    else if(question.type == QuestionType.check) question.answers.forEach((_, i) => form[question.slug][i] = 0)
  }


  ngOnInit(){
    super.ngOnInit();
    this.question.slug = this.question.slug.toLowerCase() //security measure
      .replace(/\s+/, '_')
      .replace(/_+/, '_')
    ;
    this.form[this.question.slug] = {};
    Question.set(this.question, this.form);
  }

  /**
   * wrapper function
   * @see https://stackoverflow.com/a/44045373/2652918
   */
  get questionType(){ return QuestionType }

  /**
   * this function is used to update the form values
   * @param i the position of the answer
   */
  update(i: Number){
    /*
    if(this.question.type == QuestionType.radio) this.question.answers.forEach((_, j) => this.form[this.question.key][j] = i == j);
    else if(this.question.type == QuestionType.check) this.form[this.question.key][i] = !this.form[this.question.key][i];
    */

    //the binary is used here to avoid having to convert the values on the server side
    if(this.question.type == QuestionType.radio) this.question.answers.forEach((_, j) => this.form[this.question.slug][j] = i == j ? 1 : 0);
    else if(this.question.type == QuestionType.check) this.form[this.question.slug][i] = (this.form[this.question.slug][i] == 1 ? 0 : 1);

    //console.log(this.form, this.question.slug, i, this.form[this.question.slug][i])
  }
}
