/**
 * PM (04.07.2019)
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 */
import {Component, Input, OnInit} from '@angular/core';
import {Element} from './Element';
import {LanguageService} from './language.service';

export class Section extends Element {
  @Input() language: LanguageService;
  //ngOnInit(){}
}
