/**
 * PM (04.07.2019)
 */
import {Element} from './Element';
import {LanguageService} from './language.service';
import * as $ from 'jquery';
import {AfterViewInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Thesaurus} from '../thesauri/thesaurus';

export class Page extends Element implements AfterViewInit {
  protected scroller: any;
  //private modal: {container, button, title, body};
  private modal = {title: Thesaurus._, body: Thesaurus._, container: null, button: null};
  protected done: any;
  protected error: any;


  constructor(protected language: LanguageService, protected http: HttpClient){ super() }

  ngOnInit(){
    super.ngOnInit();
    this.scroller = $('html,body'); //html for safari, body for the rest of the major browsers
    this.language.set(); //DO NOT REMOVE THIS LINE: the language must be set before the html snippet are rendered
  }

  ngAfterViewInit(): void {
    this.modal = {
      container: document.getElementById("modal_container"),
      button: document.getElementById("modal_container_proxy"),
      title: Thesaurus._,
      body: Thesaurus._
    }
  }


  protected fetch(options, success){
    this.error = ''
    this.http
      .post("server/index.php", options)
      //.post(window.location.href +"server/data.php", options)
      .subscribe(
        data => {
          this.done = true;
          if('error' in data){
            //@ts-ignore
            const error = data.error.trim();
            this.error = error.includes(' ') ? error : this.language.theo.errors[error];
            this.openModalError(this.error)
          }else success(data)
        },
        error => {
          this.done = true;
          this.error = error;
          this.openModalError(error)
        },
        () => console.log("DONE!!!")
      )
  }

  /**
   * this function is used to open the modal after updating it
   * @param title {string}
   * @param body {string}
   */
  protected openModal(title: string, body: string){
    this.modal.title = title;
    this.modal.body = body;
    this.modal.button.click() //open the modal by proxy
  }

  protected openModalError(body: string){ this.openModal(this.language.theo.error, body) }

}
