import {Page} from '../../utils/Page';
import {Component, ViewEncapsulation} from '@angular/core';
import {LanguageService} from '../../utils/language.service';
import {HttpClient} from '@angular/common/http';
import {Pool, Pool0} from '../../thesauri/Pool';
import {Pools202001} from '../../thesauri/Pools.2020.01';
import {Question} from '../../sections/question/question.component';
import {DataService} from '../../utils/data.service';
import {detect} from 'detect-browser';
import {Thesaurus} from '../../thesauri/thesaurus';
import Timer = NodeJS.Timer;
import {ActivatedRoute} from '@angular/router'; /** @since PM (11.12.2019) @see https://www.npmjs.com/package/detect-browser */

/**
 *
 */
export enum PageArticle {
  screensaver,
  form,
  saving,
  notification
}

/**
 * @since PM (09.12.2019) this class is used to handle the surveys
 * @see https://angular.io/api/common/NgIf
 */
@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class HomeComponent extends Page {
  readonly slug = 'survey';
  protected debug = false;

  private articlePrev: PageArticle; //the previous article
  private article = DataService.devMode || this.debug ? PageArticle.screensaver : PageArticle.screensaver; //the current article
  private pool: Pool = new Pool0(this.language);
  private loaded = false;
  private data = { //PM (10.12.2019) this object is used to monitor the changes occurring in the DOM
    lang: this.language.theo.slug,
    language: this.language.theo.label,
    pool: '',
    email: DataService.devMode && this.debug ? 'patrick.meppe@'+ DataService.host : '',
    newsletter: false,
    form: {}
  };
  private notification: string = '';
  private readonly browser = detect().name; //used to make some improvements on iOS
  private timer: Timer;
  private paused = this.browser == 'ios';


  constructor(protected language: LanguageService, protected http: HttpClient, private route: ActivatedRoute){ super(language, http) }

  ngOnInit(){
    super.ngOnInit();
    //DO NOT SWITCH
    this.fetch(
      {pool: this.route.snapshot.paramMap.get('pool') || '', action: 'start'}, //`pool` is null if undefined
      data => { //load & set the pool
        const pool = new Pools202001(this.language);
        if(pool.slug == data.pool){
          this.pool = pool;
          this.data.pool = data.pool;
          this.loaded = true;

          //this.form is implicitly filled after once this invocation of this function is done
        }
      }
    )
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    //console.log(this.browser, this.data)

    /*
    setTimeout(
      _ => {alert(document.getElementsByTagName('video').item(0));document.getElementsByTagName('video').item(0).play()},
      5000
    );
    */
  }


  /**
   * work around for IPads
   * @param screensaver
   */
  private playPause(screensaver: HTMLVideoElement){
    if(this.paused){
      screensaver.play();
      this.paused = false;
    }else{
      screensaver.pause();
      this.paused = true;
    }
  }

  /**
   * this function is used to reset the timeout timer by proxy
   */
  private resetTimer(){
    clearTimeout(this.timer);
    this.timer = setTimeout(
      _ => {
        this.goto(PageArticle.screensaver);
        this.reset(); //empty the form
      },
      1000 * 60 * (DataService.devMode || this.debug ? 5 : 10) //5|10 minutes
    );
  }

  /**
   * @since PM (10.12.2019) this function is used to navigate to the form
   * @param article: PageArticle {PageArticle.form} the targeted HTML article element on the page
   */
  private goto(article: PageArticle = PageArticle.form){
    this.articlePrev = this.article;
    this.article = article;

    //timeout timer resetting logic
    if(article == PageArticle.screensaver) clearTimeout(this.timer);
    else this.resetTimer();
  }

  /**
   * @since PM (10.12.2019) this function is used to reset the form
   */
  private reset(){
    this.data.email = '';
    this.data.newsletter = false;

    const pool = this.pool.map[this.language.theo.slug];
    for(let key in pool) Question.set(pool[key], this.data.form)
  }

  /**
   * wrapper function
   * @see https://stackoverflow.com/a/44045373/2652918
   */
  private get pageArticle(){ return PageArticle }

  /**
   * @since PM (10.12.2019) this function is used to submit the form request to the server
   * @see https://www.regular-expressions.info/email.html
   * #asynchron
   */
  private submit(): void {
    if(this.data.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)){
      this.goto(PageArticle.saving); //visualise the loader
      this.fetch(
        {
          pool: this.data.pool,
          action: 'save',
          auth: btoa(btoa(btoa(btoa(window.location.hostname) + this.data.email) + this.data.pool) + DataService.devFlag), //lightweight security feature
          data: this.data
        },
        resp => {
          if('success' in resp){
            this.reset(); //reset the form
            this.notification = this.language.theo.notifications[resp.success];
            if(resp.newsletter) this.notification += `<br><em>${this.language.theo.newsletters[resp.newsletter]}</em>`;
            //DO NOT SWITCH
            this.goto(PageArticle.notification)
          }else this.openModalError(this.language.theo.notification_error) //implicitly go back to the previously active HTML article element
        }
      );
    }else super.openModalError(this.language.theo.notification_email) //DO NOT USE ``this.``, if used the previously active HTML article element will be turned back on
  }


  /**
   * in addition to the native behaviour the control of the active HTML article element is partially done here
   * @override
   * @param body
   */
  protected openModalError(body: string){
    super.openModalError(body);
    if(this.articlePrev) this.goto(this.articlePrev);
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class HomeDebugComponent extends HomeComponent { debug = true }
