import { Injectable } from '@angular/core';
import {LanguageService} from './language.service';
import {Thesaurus} from '../thesauri/thesaurus';

@Injectable({
    providedIn: 'root',
})

/**
 * PM (21.06.219)
 * this service is used as proxy to avoid errors during the initialisation before the values are fetched from the server
 */
export class DataService {
  static readonly devFlag = 'srv05.24743.serviceprovider.de';
  static readonly host = 'sigmasport.com';
  static readonly devMode = !window.location.hostname.includes(DataService.host.split('.')[0]) || window.location.href.includes(DataService.devFlag); //TRUE if local || staging
  static readonly company_url = 'https://'+ DataService.host + (window.location.href.includes(DataService.devFlag) ? '.'+ DataService.devFlag : '');
  static readonly company = 'SIGMA SPORT';
  static readonly copyright = `&copy; ${DataService.company} ${new Date().getFullYear()}`;

  offset = 0;
  categories: {label, active}[] = [];
  articles: {active, id, category, alias, region, region_preview, title, title_preview, content, image, track}[] = [];
  article: any;


  //constructor(){}

  /**
   * this function is used to set/fill the various objects required in the frontend
   * @param data: {}
   */
  set(data): boolean {
    this.offset = data.offset; //load more: next block

    this.categories = data.categories.map( category => { return {label: category, active: true} });
    //data.articles.forEach( article => this.articles.push(DataService.finalise(article)) ); //articles
    DataService.transfer(data.articles, this.articles);

    //article / to focus on
    if('article' in data) this.article = DataService.finalise(data.article)

    return true
  }

  /**
   * PM (12.08.2019) this function is used to finalise the data of the given article
   * @param article the raw value from the server
   * @return {} the parsed object representing the article on the client side
   */
  static finalise(article: {id, category, alias, region, title, content, image, track}): {active, id, category, alias, region, region_preview, title, title_preview, content, image, track}{
    return {
      active: false,
      id: article.id,
      category: article.category,
      alias: article.alias.trim() || article.id,

      region: article.region.trim(),
      region_preview: this.preview(article.region),

      title: article.title.trim(),
      title_preview: this.preview(article.title),

      //content_preview: this.preview(article.content), can't be used here because of the HTML tags
      content: article.content
        .trim()
        .replace(/\r/g, '') //remove the windows pre-linebreak character
        .replace(/\n{2,}/g, '<br><br>') //replace legitimate linebreaks by the HTML counterpart
        .replace(/([^>])[ \t]*\n/g, '$1<br>') //...
      ,

      image: article.image ? `_uploads/${article.id}/${article.image}` : `assets/images/articles/placeholder.png`,
      track: article.track ?
        'server/articles.php?action=download&track='+ encodeURIComponent(`${article.id}/${article.track}`) :
        ''
    };
  }

  /**
   * this function is used to parse & store the articles from their raw - to their final -format
   * @param raw the raw articles
   * @param list the list of the final articles
   */
  static transfer(raw: {id, category, alias, region, title, content, image, track}[], list: {active, id, category, alias, region, region_preview, title, title_preview, content, image, track}[]){
    raw.forEach( article => list.push(DataService.finalise(article)) );
  }

  /**
   *
   * @param text
   * @param amOfWords Number 16 is the empiric default value
   * @param sep
   */
  static preview(text: string, amOfWords: number = 16, sep: string = ' '): string {
    text = text.trim()
    const words = text.split(sep)

    return words.length > amOfWords ? words.slice(0, amOfWords - 2).join(sep) +"..." : text
  }
}
